import React from "react"
//DATA
//STYLES
import { H1, H4, LabelBW, Text } from "../utils/typo"
import { ContainerMob, Section, Logo } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"

const Zamowienia = () => {
  return (
    <div>
      <Seo title="Zamówienia" slug="/zamowienia" />
      <Layout>
        <Logo />
        <Section
          pt="6rem"
          pb="3rem"
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="600"
        >
          <ContainerMob>
            <LabelBW mb="2rem">Zamówienia internetowe</LabelBW>
            <H1>Zamówienia składane przez naszą stronę internetową</H1>
            <Text mb="2rem" mt="2rem">
              Automatyczne zamówienia poprzez stronę internetową nie są jeszcze
              dostępne. <br />
              Trwają prace przygotowawcze. <br />
              Zapraszamy do kontaktu mailowego oraz telefonicznego.
            </Text>
            <H4>
              tel: +48 732 429 032 <br />
              mail: iks.druk @gmail.com
            </H4>

            <Text mb="2rem" mt="2rem">
              max. rozmiar załącznika mailowego: 25MB <br />
              większe pliki prosimy przesyłać za pomocą: <br />
              <a href="https://wetransfer.com/" target="blank">
                www.wetransfer.com
              </a>
            </Text>
          </ContainerMob>
        </Section>
      </Layout>
    </div>
  )
}
export default Zamowienia
